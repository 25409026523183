import { createGlobalStyle } from 'styled-components';

// Typescript ------------------------------------------------------------------
export interface ThemeType {
  breakpoints: {
    tablet: string;
    desktop: string;
  };
  colors: {
    primaryBlue: string;
    secondaryBlue: string;
    transparentSecondaryBlue: string;
    softBlack: string;
    softWhite: string;
    orangeCTA: string;
    black: string;
    white: string;
    transparentWhite: string;
    grey: string;
  };
  shadows: {
    blue: string;
    grey: string;
  };
  borderRadius: string;
  typography: {
    fontFamily: {
      headingFont: string;
      bodyFont: string;
    };
    lineHeight: {
      [key: string]: {
        default: number;
        tablet: number;
        desktop: number;
      };
    };
    fontSize: {
      [key: string]: {
        default: string;
        tablet: string;
        desktop: string;
      };
    };
    fontWeight: {
      default: string;
      light: number;
      bold: string;
    };
    letterSpacing: {
      default: string;
      tablet: string;
      desktop: string;
    };
  };
  spacing: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
  };
}
// -----------------------------------------------------------------------------

export const useCustomTheme = (): ThemeType => ({
  breakpoints: {
    tablet: '768px',
    desktop: '1024px',
  },
  colors: {
    primaryBlue: '#0080B2',
    secondaryBlue: '#8BD3F5',
    transparentSecondaryBlue: 'rgba(139, 211, 245, 0.3)',
    softBlack: '#1C1C1C',
    softWhite: '#F5F5F5',
    orangeCTA: '#FF7F50',
    black: '#000000',
    white: '#FFFFFF',
    transparentWhite: 'rgba(255, 255, 255, 0.7)',
    grey: '#A0A0A0',
  },
  shadows: {
    blue: '0px 4px 20px 5px rgba(155, 209, 240, 1)',
    grey: '0px 4px 20px 5px rgba(160, 160, 160, 0.55)',
  },
  borderRadius: '15px',
  typography: {
    fontFamily: {
      headingFont: 'Merriweather, serif',
      bodyFont: 'Nunito, sans-serif',
    },
    lineHeight: {
      h1: {
        default: 1.4,
        tablet: 1.3,
        desktop: 1.2,
      },
      h2: {
        default: 1.4,
        tablet: 1.35,
        desktop: 1.3,
      },
      h3: {
        default: 1.45,
        tablet: 1.45,
        desktop: 1.4,
      },
      h4: {
        default: 1.5,
        tablet: 1.45,
        desktop: 1.4,
      },
      h5: {
        default: 1.55,
        tablet: 1.5,
        desktop: 1.45,
      },
      h6: {
        default: 1.6,
        tablet: 1.5,
        desktop: 1.45,
      },
      p: {
        default: 1.7,
        tablet: 1.65,
        desktop: 1.6,
      },
    },
    fontSize: {
      h1: {
        default: 'clamp(28px, 5vw, 40px)',
        tablet: 'clamp(32px, 4.5vw, 48px)',
        desktop: 'clamp(36px, 4vw, 56px)',
      },
      h2: {
        default: 'clamp(24px, 4.5vw, 36px)',
        tablet: 'clamp(28px, 4vw, 44px)',
        desktop: 'clamp(32px, 3.5vw, 48px)',
      },
      h3: {
        default: 'clamp(22px, 4vw, 32px)',
        tablet: 'clamp(26px, 3.5vw, 40px)',
        desktop: 'clamp(28px, 3vw, 44px)',
      },
      h4: {
        default: 'clamp(20px, 3.5vw, 28px)',
        tablet: 'clamp(22px, 3vw, 36px)',
        desktop: 'clamp(24px, 2.5vw, 40px)',
      },
      h5: {
        default: 'clamp(18px, 3vw, 24px)',
        tablet: 'clamp(20px, 2.5vw, 30px)',
        desktop: 'clamp(22px, 2vw, 36px)',
      },
      h6: {
        default: 'clamp(16px, 2.5vw, 20px)',
        tablet: 'clamp(18px, 2vw, 24px)',
        desktop: 'clamp(20px, 1.75vw, 28px)',
      },
      p: {
        default: '16px',
        tablet: '16px',
        desktop: '16px',
      },
    },
    fontWeight: {
      default: 'normal',
      light: 300,
      bold: 'bold',
    },
    letterSpacing: {
      default: '0.05em',
      tablet: '0.04em',
      desktop: '0.03em',
    },
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
  },
});

export const GlobalStyle = createGlobalStyle`
    *,
    *::before,
    *::after {
        margin: 0;
        padding: 0;
        border: none;
        box-sizing: border-box;
    }
    html {
        font-size: 16px;
    }
    body {
        height: 100%;
        color: ${({ theme }) => theme.colors.softBlack};
        background: ${({ theme }) => theme.colors.white};
    }
`;
