import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, useCustomTheme } from './src/styles/theme';

export const UseWrapWithProvider = ({
	element,
}: {
	element: React.ReactNode;
}) => {
	const theme = useCustomTheme();

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			{element}
		</ThemeProvider>
	);
};
